import React, { useEffect } from "react";
import { GET_QR_PRODUCT } from "./backend";
import { useQuery } from "@apollo/client";
import Spinner from "./Spinner";

interface IQrProductProps {
  id: string;
  type: string;
}

export const QrProductsDownloadPage = ({ id, type }: IQrProductProps) => {
  const { data, loading, error } = useQuery(GET_QR_PRODUCT, {
    variables: { id },
  });

  useEffect(() => {
    if (error) {
      console.error("Error while fetching qr product", error);
      return;
    }

    if (data?.qrProduct?.pdfUrl || data?.qrProduct?.pngUrl) {
      const link = document.createElement("a");
      link.href =
        type === "pdf" ? data?.qrProduct?.pdfUrl : data?.qrProduct?.pngUrl;
      link.setAttribute("download", `FileName.pdf`);

      document.body.appendChild(link);
      link.click();
      void link.parentNode.removeChild(link);
    }
  }, [data, error, type]);

  if (loading)
    return (
      <div className="w-full h-full flex pt-[50vh] justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <div className="container py-36 mx-auto">
      <div className="flex justify-center align-middle">
        <div className="bg-white py-16 px-20 min-w-[300px] max-w-[400px] rounded-lg border shadow-lg">
          <h2 className="text-blue-700 text-center">Stažení QR produktu</h2>
          {loading ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="flex flex-col gap-8">
              <p className="text-sm text-black-800 text-center">
                Právě proběhlo stažení QR produktu pro vaši firmu, pokud se
                stahování nespustilo automaticky, můžete si QR produkt stáhnout
                pomocí následujících tlačítek
              </p>

              {data?.qrProduct?.pdfUrl && (
                <a
                  href={data?.qrProduct?.pdfUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full px-14 py-10 text-center text-lg bg-blue-700 text-white rounded-md hover:bg-blue-800 transition-all duration-300"
                >
                  Stáhnout PDF
                </a>
              )}
              {data?.qrProduct?.pngUrl && (
                <a
                  href={data?.qrProduct?.pngUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full px-14 py-10 text-center text-lg bg-blue-700 text-white rounded-md hover:bg-blue-800 transition-all duration-300"
                >
                  Stáhnout PNG
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
