import React from "react"
import cn from "classnames"

type LinkKind = "primary" | "bare" | "nav" | "footer" | "custom"
interface LinkProps {
	children: React.ReactNode
	href?: string | null
	className?: string
	target?: "_blank" | "_self"
	kind?: LinkKind
	disabled?: boolean
	title?: string
	urlIsRelative?: boolean
	ariaLabel?: string
}

const stylesByKind: Record<LinkKind, string> = {
	primary: "text-primary underline hover:text-blue-900",
	nav: "no-underline text-black-700 hover:text-black-800 hover:underline self-center",
	footer: "no-underline text-blue-700 hover:text-blue-800 hover:underline text-14",
	bare: "no-underline",
	custom: "",
}

export const Link: React.FC<LinkProps> = (props) => {
	const { children, className, kind, disabled, title, urlIsRelative = false, ariaLabel } = props

	let href = props.href?.trim() || undefined
	if (href && !urlIsRelative) {
		if (
			!["/", "http://", "https://", "mailto:"].find((protocol) => href!.startsWith(protocol))
		) {
			href = `http://${href}`
		}
	}
	const leadsOutside = !href?.startsWith("/")

	const getTarget = () => {
		if (href) {
			if (props.target) {
				return props.target
			}
			if (leadsOutside) return "_blank"
		}
		return undefined
	}
	const target = getTarget()

	const anchor = (
		<a
			className={cn(
				"cursor-pointer",
				kind && stylesByKind[kind],
				className,
				disabled && "pointer-events-none"
			)}
			title={title}
			target={target}
			href={href}
			rel={target === "_blank" ? "noreferrer" : undefined}
			aria-label={ariaLabel}
		>
			{children}
		</a>
	)

	return anchor
}
