import React from "react"
import { Trans, t } from "@lingui/macro"
import { ReactComponent as Logo } from "images/logo.svg"
import { ReactComponent as AvatarIcon } from "../icons/avatar-alt.svg"
import { BurgerMenu } from "./Menu"
import { Tooltip } from "./Tooltip"
import { Link } from "./Link"
import { ReviewDialogButton } from "./ReviewDialogButton"
import { LanguageSelector } from "./LanguageSelector"

import { ReactComponent as CatalogIcon } from "icons/header/catalog.svg"
import { ReactComponent as CalculatorIcon } from "icons/header/calculator.svg"
import { ReactComponent as ProjectManagersIcon } from "icons/header/project-manager.svg"
import { ReactComponent as HeatpumpIcon } from "icons/header/heat-pump.svg"
import { ReactComponent as PhotovoltaicsIcon } from "icons/header/photovoltaics.svg"
import { ReactComponent as AirRecuperationIcon } from "icons/header/air-recuperation.svg"

export const Header = () => {
	return (
		<header className="w-screen fixed top-0 bg-white z-50">
			<nav className="flex flex-wrap items-center justify-between container mx-auto 2xl:max-w-screen-2xl py-[20px]">
				<div className="flex items-center">
					<Link kind="bare" href="/" className="mr-[16px]">
						<Logo className="text-blue-700" />
						<span className="sr-only">QR produkty | Refsite</span>
					</Link>
					<span className="text-black-800 hidden xl:inline">
						<Trans id="header.title">ověřené reference ze stavebnictví</Trans>
					</span>
				</div>
				<div className="flex items-center">
					<div className="md:hidden mr-24 ml-auto">
						<ReviewDialogButton />
					</div>
					<BurgerMenu>
						<div className="flex flex-col md:flex-row-reverse md:gap-[32px]">
							<ul className="flex flex-col gap-[16px] md:gap-[8px] md:flex-row-reverse mt-[16px] md:mt-0">
								<LinkListItem
									name={t({
										id: "header.catalog",
										message: "Katalog úspor",
									})}
									href="https://wiki.refsite.info"
									icon={<CatalogIcon className="text-black-900 w-[24px]" />}
								/>
								<LinkListItem
									name={t({
										id: "header.calculators",
										message: "Kalkulačky",
									})}
									href="https://newtools.refsite.info"
									icon={<CalculatorIcon className="text-black-900 w-[24px]" />}
								/>
								<LinkListItem
									name={t({
										id: "header.projectManagers",
										message: "Projektanti a specialisté",
									})}
									href="https://refsite.info/project-managers"
									icon={
										<ProjectManagersIcon className="text-black-900 w-[24px]" />
									}
								/>
							</ul>
							<ul className="flex flex-col-reverse md:flex-row-reverse mt-[16px] md:mt-0 gap-[16px] md:gap-[8px]">
								<LinkListItem
									name={t({
										id: "header.photovoltaics",
										message: "Fotovoltaika",
									})}
									href="https://refsite.info/photovoltaics/reviews"
									icon={<PhotovoltaicsIcon className="text-black-900 w-[24px]" />}
								/>
								<LinkListItem
									name={t({
										id: "header.heatPumps",
										message: "Tepelná čerpadla",
									})}
									href="https://refsite.info/heating/heat-pumps/reviews"
									icon={<HeatpumpIcon className="text-black-900 w-[24px]" />}
								/>
								<LinkListItem
									name={t({
										id: "header.heatRecoveryVentilation",
										message: "Větrání s rekuperací tepla",
									})}
									href="https://refsite.info/heat-recovery-ventilation/reviews"
									icon={
										<AirRecuperationIcon className="text-black-900 w-[24px]" />
									}
								/>
							</ul>
						</div>
						<div className="hidden md:flex">
							<ReviewDialogButton />
						</div>
						<div>
							<Link
								kind="nav"
								className="flex items-center w-full md:w-auto md:bg-black-200 md:rounded md:p-8 md:hover:bg-black-300 md:hover:no-underline md:hover:text-black-900 md:text-black-900 md:font-bold"
								href={"https://refsite.info/login"}
							>
								<div className="w-40 h-40 ml-16 mr-8 md:mx-0 md:hidden">
									<div className="w-40 h-40 flex justify-center items-center bg-black-200 rounded-lg">
										<AvatarIcon className="text-black-900 h-20" />
									</div>
								</div>
								<div>Přihlásit</div>
							</Link>
						</div>
					</BurgerMenu>
				</div>
			</nav>
			<hr className="border-1 border-black-200 sticky top-0 z-20" />
		</header>
	)
}

interface LinkListItemProps {
	name: any
	href: string
	icon: React.ReactNode
}

const LinkListItem: React.FC<LinkListItemProps> = ({ name, href, icon }) => {
	return (
		<li>
			<Link
				kind="nav"
				className="flex items-center"
				href={href}
				target="_blank"
				ariaLabel={name}
			>
				<div className="w-[40px] h-[40px] ml-[16px] mr-[8px] md:mx-0">
					<Tooltip tip={name}>
						<div className="w-[40px] h-[40px] flex justify-center items-center bg-black-200 rounded-lg">
							{icon}
							<span className="sr-only">{name}</span>
						</div>
					</Tooltip>
				</div>
				<div className="md:hidden">{name}</div>
			</Link>
		</li>
	)
}
