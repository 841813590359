import React from "react";
import { useParams } from "react-router";

import { Header } from "./Header";
import { QrProductsDownloadPage } from "components/QrProductsDownloadPage";

const HomePage = () => {
  const params = useParams();
  const id = params["id"];
  const slug = params["type"];

  return (
    <div>
      <Header />
      <QrProductsDownloadPage id={id} type={slug} />
      {/* <Footer /> */}
    </div>
  );
};

export default HomePage;
