import React from "react"
import { Routes, Route } from "react-router-dom"
import HomePage from "view_components/HomePage"

function App() {
	return (
		<div className="App">
				<Routes>
					<Route index path="/" element={<HomePage />} />
					<Route path="/:id/:type" element={<HomePage />} />
				</Routes>
		</div>
	)
}

export default App
