import React from "react"
import cn from "classnames"
import { useState } from "react"
import styles from "./BurgerMenu.module.css"

interface IBurgerMenuProps {
	className?: string
	children: React.ReactNode
}

export const BurgerMenu: React.FC<IBurgerMenuProps> = ({ children }) => {
	const [open, setOpen] = useState<boolean>(false)

	return (
		<div className={cn("flex", open ? styles.open : "")}>
			<div className={styles.overlay} onClick={() => setOpen(false)} />
			<div role="button" className={styles.button} onClick={() => setOpen(!open)}>
				<div />
				<div />
				<div />
			</div>

			<div className={cn(styles.menu, "space-y-[16px] md:space-y-0")}>{children}</div>
		</div>
	)
}
