import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import {
	ApolloClient,
	//createHttpLink,
	InMemoryCache,
	ApolloProvider,
	DefaultOptions,
} from "@apollo/client"

import { Settings } from "./settings"
import { HelmetProvider } from "react-helmet-async"

import { i18n } from "@lingui/core"
import { I18nProvider } from "@lingui/react"
import { messages as csMessages } from "./i18n/cs/messages"
import { messages as enMessages } from "./i18n/en/messages"
import { messages as deMessages } from "./i18n/de/messages"
import { messages as huMessages } from "./i18n/hu/messages"
import { messages as bgMessages } from "./i18n/bg/messages"
import { messages as plMessages } from "./i18n/pl/messages"
import { messages as hrMessages } from "./i18n/hr/messages"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: "no-cache",
		errorPolicy: "ignore",
	},
	query: {
		fetchPolicy: "no-cache",
		errorPolicy: "all",
	},
}

const client = new ApolloClient({
	uri: `${Settings.ApiAddress}graphql`,
	cache: new InMemoryCache(),
	defaultOptions: defaultOptions,
	// headers: {
	//   authorization: `Bearer ${localStorage.getItem(TOKEN_STORAGE_KEY)}`
	// },
	//credentials: 'include',
})

i18n.load("cs", csMessages)
i18n.load("en", enMessages)
i18n.load("de", deMessages)
i18n.load("hu", huMessages)
i18n.load("bg", bgMessages)
i18n.load("pl", plMessages)
i18n.load("hr", hrMessages)
i18n.activate(window.localStorage.getItem("language") || "cs")

root.render(
	<React.StrictMode>
		<I18nProvider i18n={i18n}>
			<HelmetProvider>
				<ApolloProvider client={client}>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</ApolloProvider>
			</HelmetProvider>
		</I18nProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
