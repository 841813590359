import cn from "classnames"
import { Markdown } from "components/types/components/Markdown"
import React, { RefObject, useContext, isValidElement } from "react"
import { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { ReactElement, ReactNode } from "react-markdown/lib/react-markdown"
import styles from "./Tooltip.module.css"

// TODO: Add some context to tooltip children let know when tooltip is opened. Hover does not work on 100%.
function clamp(number: number, min: number, max: number) {
	return Math.max(min, Math.min(number, max))
}

type TooltipSize = "sm" | "md" | "lg"
interface FloatingTipProps {
	size: TooltipSize
	children: ReactNode
}

const stylesBySize: Record<TooltipSize, string> = {
	sm: "p-[10px] sm:max-w-[300px]",
	md: "p-[20px] sm:max-w-[300px]",
	lg: "p-[20px]",
}

const FloatingTip: React.FC<FloatingTipProps> = ({ children, size }) => {
	const ref = useRef<HTMLDivElement>(null)
	const arrowRef = useRef<HTMLDivElement>(null)
	const [mounted, setMounted] = useState(false)
	const { wrapper, closing } = useContext(OverlayProvider)
	const open = mounted && !closing

	useEffect(() => {
		setMounted(true)
		const move = () => {
			if (ref.current && wrapper.current) {
				const box = wrapper.current.getBoundingClientRect()
				const w = ref.current.clientWidth
				const documentWidth = document.documentElement.clientWidth
				const x = clamp(box.left + box.width / 2 - w / 2, 0, documentWidth - w)
				const y = box.top + window.scrollY + box.height
				ref.current.setAttribute("style", `top: ${y}px; left: ${x}px`)

				if (arrowRef.current) {
					const arrowX = box.left + box.width / 2 - x
					arrowRef.current.setAttribute("style", `transform: translateX(${arrowX}px`)
				}
			}
		}
		move()
	}, [])

	return (
		<div ref={ref} className={cn("absolute mt-[8px] pointer-events-none z-100")}>
			<div ref={arrowRef} className={cn("absolute", styles.arrow, open && styles.open)}></div>
			<div
				className={cn(
					"rounded-lg shadow transition origin-top duration-200 bg-primary text-white mx-[8px] sm:mx-[16px] lg:mx-[32px]",
					stylesBySize[size],
					open ? "translate-y-0 scale-100 opacity-100" : "opacity-50 scale-50",
					closing ? "ease-slow-in opacity-0 scale-0" : "ease-fast-in"
				)}
			>
				{isValidElement(children) ? children : <Markdown>{children as string}</Markdown>}
			</div>
		</div>
	)
}

interface OverlayTriggerProps {
	overlay: ReactElement
	wrapperClassName?: string
	closeDelay?: number
	children: ReactNode
}

interface OverlayContext {
	wrapper: RefObject<HTMLElement>
	closing: boolean
}

const OverlayProvider = React.createContext<OverlayContext>({
	wrapper: { current: null },
	closing: false,
})

const OverlayTrigger: React.FC<OverlayTriggerProps> = ({
	children,
	overlay,
	wrapperClassName,
	closeDelay = 0,
}) => {
	const wrapper = useRef<HTMLDivElement>(null)
	const [isOpen, setIsOpen] = useState(false)
	const [closing, setClosing] = useState(false)

	const open = () => {
		setIsOpen(true)
		setClosing(false)
	}
	const close = () => {
		setIsOpen(false)
		setClosing(true)
	}

	useEffect(() => {
		if (closing) {
			setTimeout(() => {
				if (closing) setClosing(false)
			}, closeDelay)
		}
	}, [closing])

	return (
		<div
			ref={wrapper}
			className={cn("inline-block", wrapperClassName)}
			onMouseEnter={open}
			onMouseLeave={close}
			onTouchStart={open}
			onTouchEnd={close}
		>
			{children}
			{(isOpen || closing) &&
				wrapper.current &&
				document?.body &&
				ReactDOM.createPortal(
					<OverlayProvider.Provider value={{ wrapper, closing }}>
						{overlay}
					</OverlayProvider.Provider>,
					document.body
				)}
		</div>
	)
}

interface TooltipProps {
	tip: ReactNode
	size?: TooltipSize
	/** className for wrapper */
	className?: string
	children: ReactNode
}

export const Tooltip: React.FC<TooltipProps> = ({ children, tip, className, size = "md" }) => {
	return (
		<OverlayTrigger
			wrapperClassName={className}
			overlay={<FloatingTip size={size}>{tip}</FloatingTip>}
			closeDelay={250}
		>
			{children}
		</OverlayTrigger>
	)
}
