import { gql, ApolloClient, InMemoryCache } from "@apollo/client";
import { Settings } from "settings";

export const RefsiteClient = new ApolloClient({
  uri: "https://api.refsite.info/graphql",
  cache: new InMemoryCache(),
});

export const CalcClient = new ApolloClient({
  uri: `${Settings.ApiAddress}graphql`,
  cache: new InMemoryCache(),
});

export const GET_QR_PRODUCT = gql`
  query GetQrProduct($id: String!) {
    qrProduct(_id: $id) {
      _id
      creationDate
      pdfUrl
      pngUrl
    }
  }
`;
