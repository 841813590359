import React from "react"
import ReactModal from "react-modal"
import css from "./Modal.module.css"
import { ReactComponent as Close } from "icons/close.svg"

interface ModalProps {
	isOpen: boolean
	onClose: () => unknown
	maxWidth?: number
	title?: React.ReactNode
	children?: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({ children, isOpen, onClose, maxWidth, title }) => {
	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onClose}
			closeTimeoutMS={200}
			overlayClassName={{
				base: css.overlay,
				afterOpen: css.afterOpen,
				beforeClose: css.beforeClose,
			}}
			className={css.content}
		>
			<div
				className="bg-white sm:rounded-lg shadow min-w-0 flex flex-col p-32 robotofont"
				style={{ width: maxWidth }}
			>
				<div className={"flex justify-between items-center mb-8"}>
					<h3 className="font-bold text-black-900 text-24">{title}</h3>
					<button onClick={onClose} className="font-bold">
						<Close className="h-24 w-24 fill-black-700 text-black-700 stroke-black-700" />
					</button>
				</div>
				<div className="mt-32">{children}</div>
			</div>
		</ReactModal>
	)
}
