import React, { useState, useCallback } from "react"
import { Modal } from "../Modal"
import { Link } from "../Link"
import cn from "classnames"

import { ReactComponent as HeatPump } from "icons/header/heat-pump.svg"
import { ReactComponent as HeatRecoveryVentilation } from "icons/header/air-recuperation.svg"
import { ReactComponent as Photovoltaics } from "icons/header/photovoltaics.svg"

interface ReviewDialogButtonProps {
	className?: string
	children?: React.ReactNode
}

const getLinkToAddReview = (type: string) => {
	return `https://refsite.info/add-review?productType=${type}`
}

export const ReviewDialogButton: React.FC<ReviewDialogButtonProps> = ({ className, children }) => {
	const [isOpen, setIsOpen] = useState(false)
	const handleToggle = useCallback(() => setIsOpen((s) => !s), [setIsOpen])

	return (
		<>
			<button
				onClick={handleToggle}
				className="text-white bg-blue-700 hover:bg-blue-800 font-bold rounded p-[8px] self-center transition-colors"
			>
				Přidat recenzi
			</button>
			<Modal isOpen={isOpen} onClose={handleToggle} title={"Přidat recenzi pro"}>
				<div className="flex flex-col space-y-16">
					<ReviewLink
						className="justify-start p-16"
						href={getLinkToAddReview("heat-pump")}
					>
						<IconContainer>
							<HeatPump className="inline text-blue-700 w-32" />
						</IconContainer>
						Tepelná čerpadla
					</ReviewLink>
					<ReviewLink
						className="justify-start p-16"
						href={getLinkToAddReview("photovoltaics")}
					>
						<IconContainer>
							<Photovoltaics className="inline text-blue-700 w-32" />
						</IconContainer>
						Fotovoltaiku
					</ReviewLink>
					<ReviewLink
						className="justify-start p-16"
						href={getLinkToAddReview("heat-recovery-ventilation")}
					>
						<IconContainer>
							<HeatRecoveryVentilation className="inline text-blue-700 w-32" />
						</IconContainer>
						Větrání s rekuperací tepla
					</ReviewLink>
				</div>
			</Modal>
		</>
	)
}

interface ReviewLinkProps {
	href: string
	className?: string
	children?: React.ReactNode
}

const ReviewLink: React.FC<ReviewLinkProps> = ({ children, href, className }) => (
	<Link
		className={cn(
			"flex-1 flex items-center text-20 text-blue-800 border border-blue-700 rounded-lg text-center hover:border-blue-200 hover:bg-blue-200",
			className
		)}
		kind="bare"
		href={href}
		target="_blank"
	>
		{children}
	</Link>
)

const IconContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<div className="w-48 h-48 flex justify-center items-center bg-blue-100 rounded-lg mr-16 overflow-hidden shrink-0">
		{children}
	</div>
)
