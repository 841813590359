import React from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw"

const LinkRenderer = (props: any) => {
	return (
		<a
			href={props.href}
			target="_blank"
			rel="noreferrer"
			className="text-primary hover:text-primary-dark underline transition-colors"
		>
			{props.children}
		</a>
	)
}

const LineRenderer = (props: any) => {
	return <hr className="!bg-black-300 h-3 rounded-lg my-20" />
}

interface MarkdownProps {
	children: string
}
export const Markdown: React.FC<MarkdownProps> = ({ children }) => {
	return (
		<ReactMarkdown
			className="whitespace-pre-wrap"
			remarkPlugins={[remarkGfm]}
			rehypePlugins={[rehypeRaw]}
			components={{ a: LinkRenderer, hr: LineRenderer }}
		>
			{children}
		</ReactMarkdown>
	)
}
